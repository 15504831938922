import React, { useState, useEffect, useRef  } from 'react';
import { useTranslation } from 'react-i18next';

const GridItem = ({ pdfPath, title, iconSrc, delay, children, category, openPdf, isTransparent }) => {
  const handleClick = (e) => {
    if (pdfPath === "analyse_de_donnees_spotify.zip") {
      e.preventDefault();
      const fullPath = `${pdfPath}`;
      const link = document.createElement('a');
      link.href = fullPath;
      link.download = pdfPath; // Suggérer un nom pour le fichier téléchargé
      document.body.appendChild(link); // Ajouter le lien au document
      link.click(); 
      document.body.removeChild(link); 
    } else if (pdfPath) {
      openPdf(pdfPath);
    }
  };  const [isHovered, setIsHovered] = useState(false);

  
  const contentStyle = isTransparent && !isHovered ? { opacity: 0.55 } : {};
  const tooltipStyle = isTransparent && isHovered ? { display: 'block' } : { display: 'none' };
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoverMessage, setHoverMessage] = useState("");
  const handleHover = (item) => {
    setHoveredItem(item);
    if (item.isTransparent) {
      setHoverMessage("En cours d'apprentissage");
    } else {
      setHoverMessage("");
    }
  };
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const parentRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    // Définit un message spécifique pour les fichiers .zip
    if (pdfPath && pdfPath.endsWith('.zip')) {
      setHoverMessage("À exécuter avec Jupyter Notebook.");
    } else if (isTransparent) {
      // Message pour les éléments transparents
      setHoverMessage("En cours d'apprentissage");
    } else {
      // Réinitialise le message pour les autres cas
      setHoverMessage("");
    }
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoverMessage(""); // Réinitialise le message lorsque la souris quitte l'élément
  };
  return (
    <div
      ref={parentRef}
      className={`col-12 col-sm-12 col-md-6 col-lg-4 d-flex mb-4 ${category === 'parcours' ? 'pdf-download' : 'pdf-open'}`}
      style={{ position: 'relative', animationName: 'fadeInUp', animationDuration: '1s', animationDelay: delay, animationFillMode: 'both', visibility: 'visible' }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        <div style={contentStyle}>
      {children}
      {/* Affichage conditionnel de l'InfoBox */}
      {isHovered && hoverMessage && (
        <div 
          className="info-box active" 
          style={{ 
            position: 'absolute', 
            bottom: '0', // Ajuster selon le besoin
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            marginBottom:"-15px",
            width:'225px',
            pointerEvents:'none',
          }}
        >
          <p className="info-text">{hoverMessage}</p>
        </div>
      )}
    </div>
    </div>
  );
  
  
};

const InfoBox = ({ message }) => {
  return (
    <div className="info-box active">
      <p className="info-text">{message}</p>
    </div>
  );
};


const HomePage = () => {
  const [isScreenWidthBelow900, setIsScreenWidthBelow900] = useState(window.innerWidth < 900);
  const openPdf = (pdfPath) => {
    // Votre logique pour ouvrir le PDF
    console.log("Opening PDF:", pdfPath);
    const encodedPath = encodeURIComponent(pdfPath);
    const fullPath = pdfPath.startsWith('/') ? `/view-pdf${encodedPath}` : `/view-pdf/${encodedPath}`;
    window.location.href = fullPath;
    if (pdfPath.endsWith('.zip')) {
      // Gère le téléchargement direct pour les fichiers ZIP
      window.location.href = pdfPath;
    } else {
      // Votre logique existante pour ouvrir les PDFs ou autres types de fichiers
      const encodedPath = encodeURIComponent(pdfPath);
      const fullPath = pdfPath.startsWith('/') ? `/view-pdf${encodedPath}` : `/view-pdf/${encodedPath}`;
      window.location.href = fullPath;
    }
  };
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('compétences');
  const filterHPRef = useRef(null);
  const compétencesRef = useRef(null);
  const travauxRef = useRef(null);
  const parcoursRef = useRef(null); // Conserver pour le bouton parcours
  const [gradientPosition, setGradientPosition] = useState('');
  const [isGradientFixed, setIsGradientFixed] = useState(true);
  const [pathsRendered, setPathsRendered] = useState(false);

 

  const [svgReady, setSvgReady] = useState(false); // Nouveau state pour contrôler l'affichage des SVG




    const gridItems = [
        {
            href: "/ExpressionCommunication",
            title: t('CommTitre'),
            iconSrc: "img/icons/express.png",
            category: "compétences"
        }, {
            href: "/LangagesDeProgrammation",
            title: t('LangProTitre'),
            iconSrc: "img/icons/programmation3.png",
            category: "compétences"
        }, {
            href: "/CalculDifferentielClassique",
            title: t('DifferentielTitre'),
            iconSrc: "img/icons/calcul_differentiel.png",
            category: "compétences"
        }, {
            href: "/DataAnalyseProbabilitesStatistique",
            title: t('DataProbTitre'),
            iconSrc: "img/icons/datalogo.png",
            category: "compétences"
        }, {
            href: "/Parallelisation",
            title: t('ParallTitre'),
            iconSrc: "img/icons/hpc.png",
            category: "compétences"
        }, {
            href: "/AlgorithmiqueAvanceeClassique",
            title: t('AlgoAvTitre'),
            iconSrc: "img/icons/Sans_titre_4.png",
            category: "compétences"
        }, {
            href: "/Numeration",
            title: t('NumTitre'),
            iconSrc: "img/icons/numlog2.png",
            category: "compétences"
        }, {
            href: "/OptimisationContinue",
            title: t('OptiContTitre'),
            iconSrc: "img/icons/opti.png",
            category: "compétences"
        }, {
            href: "/MachinesTuringDecidabilite",
            title: t('TuringTitre'),
            iconSrc: "img/icons/turing.png",
            category: "compétences"
        }, {
            href: "/SystemeExploitationArchitecture",
            title: t('SysExpArchTitre'),
            iconSrc: "img/icons/systemeexploi.png",
            category: "compétences"
        }, {
            href: "/Markov",
            title: t('MarkovTitre'),
            iconSrc: "img/icons/markov.png",
            category: "compétences"
        }, {
            href: "/InterpolationQuadrature",
            title: t('InterpoTitre'),
            iconSrc: "img/icons/simpson.png",
            category: "compétences"
        }, {
            href: "/TopologieAnalyseHilbertienne",
            title: t('TopologieTitre'),
            iconSrc: "img/icons/topo2.png",
            category: "compétences"
        }, {
            href: "/Traitement_du_signal_Analyse_de_Fourier",
            title: t('FourierTitre'),
            iconSrc: "img/icons/fourier.png",
            category: "compétences"
        }, {
            href: "/AlgorithmiqueAlgebrique",
            title: t('AlgoAlgTitre'),
            iconSrc: "img/icons/algomat.png",
            category: "compétences"
        }, {
            // href: "#",
            title: "Réseaux neuronaux artificiels",
            iconSrc: "img/icons/neural.png",
            category: "compétences",
            isTransparent: true
        }, {
            // href: "#",
            title: "Cryptographie Cybersécurité",
            iconSrc: "img/icons/cybersec2.png",
            category: "compétences",
            isTransparent: true
        }, {
            // href: "#",
            title: "Informatique quantique",
            iconSrc: "img/icons/quantique.png",
            category: "compétences",
            isTransparent: true
        }, {
            pdfPath: "Projet_PPAR_1.pdf",
            title: "Dissipation de la chaleur d’un processeur",
            iconSrc: "img/icons/pdf.png",
            category: "travaux"
        }, {
            pdfPath: "Exam_PPAR_2.pdf",
            title: "Devoir de programmation parallèle",
            iconSrc: "img/icons/pdf.png",
            category: "travaux"
        }, 
        {
          pdfPath: "travail_proba.pdf",
          title: "Simulations probabilistes",
          iconSrc: "img/icons/pdf.png",
          category: "travaux"
      },
      
      {
        pdfPath: "analyse_de_donnees_spotify.zip",
        title: "Data-analyse de Kaggle-Spotify via Python-Jupyter",
        iconSrc: "img/icons/dl.png",
        category: "travaux"
    },
    {
          pdfPath: "cv.pdf",
          title: "Mon CV",
          iconSrc: "img/icons/pdf.png", 
          category: "parcours",
      }
    ];

    const buttonClass = (category) => {
        let classes = 'filterHPButton';
        if (selectedCategory === category) {
            classes += ' scaled';
        } else if (selectedCategory !== null) {
            classes += ' inactive';
        }
        return classes;
    };

    const filteredGridItems = selectedCategory
    ? gridItems.filter(item => item.category === selectedCategory)
    : gridItems;
    
    const masonryRef = useRef(null);

    const setGradientPositionBasedOnRef = (ref) => {
      if (ref && ref.current) {
        const buttonRect = ref.current.getBoundingClientRect();
        const filterHPRect = filterHPRef.current.getBoundingClientRect();
        const xPercent = ((buttonRect.left + buttonRect.width / 2 - filterHPRect.left) / filterHPRect.width) * 100;
        const yPercent = ((buttonRect.top + buttonRect.height / 2 - filterHPRect.top) / filterHPRect.height) * 100;
        setGradientPosition(`${xPercent}% ${yPercent}%`);
      }
    };
    

    useEffect(() => {
        setGradientPositionBasedOnRef(compétencesRef);
    }, []);




   
  
    const TravauxGrid = ({ items, openPdf }) => {
      // Filtrer les items par catégorie "travaux"
      const travauxItems = items.filter(item => item.category === 'travaux');
    
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {travauxItems.map((item, index) => (
            <GridItem
              key={index}
              title={item.title}
              iconSrc={item.iconSrc}
              onClick={() => openPdf(item.pdfPath)}
            />
          ))}
        </div>
      );
    };
  
   
  
  


  useEffect(() => {
  const updateGradientStyle = () => {
    let gradientStyle;
    if (window.innerWidth < 900) {
      switch (selectedCategory) {
        case 'compétences':
          gradientStyle = `linear-gradient(to bottom, rgba(251, 2, 255, 0.9) 5%, rgba(106, 129, 251, 0.1) 60%)`;
          break;
        case 'travaux':
          gradientStyle = `linear-gradient(to bottom, rgba(106, 129, 251, 0.1) 0%, rgba(251, 2, 255, 0.8) 40%, rgba(251, 2, 255, 0.8) 55%, rgba(106, 129, 251, 0.1) 100%)`;
          break;
        case 'parcours':
          gradientStyle = `linear-gradient(to bottom, rgba(106, 129, 251, 0.1) 35%, rgba(251, 2, 255, 0.8) 95%)`;
          break;
        default:
          gradientStyle = `linear-gradient(to bottom, rgba(251, 2, 255, 1), rgba(106, 129, 251, 1))`;
      }
    } else {
      if (isGradientFixed && gradientPosition) {
        gradientStyle = `radial-gradient(circle at ${gradientPosition}, rgba(251, 2, 255, 1) 10%, rgba(106, 129, 251, 0.5) 60%)`;
      } else {
        gradientStyle = `linear-gradient(128deg, rgba(251, 2, 255, 1), rgba(106, 129, 251, 1))`;
      }
    }

    filterHPRef.current.style.backgroundImage = gradientStyle;
  };

  // Appliquez le style de gradient et ajustez-le lors du redimensionnement de la fenêtre
  window.addEventListener('resize', updateGradientStyle);
  updateGradientStyle();

  // Nettoyez l'écouteur d'événements lors du démontage
  return () => window.removeEventListener('resize', updateGradientStyle);
}, [selectedCategory, gradientPosition, isGradientFixed, isScreenWidthBelow900]);



useEffect(() => {
  
}, [selectedCategory]);




    const handleMouseMove = (event) => {
        if (!isGradientFixed) {
            const {clientX, clientY} = event;
            const filterHPRect = filterHPRef
                .current
                .getBoundingClientRect();
            const xPercent = ((clientX - filterHPRect.left) / filterHPRect.width) * 100;
            const yPercent = ((clientY - filterHPRect.top) / filterHPRect.height) * 100;
            setGradientPosition(`${xPercent}% ${yPercent}%`);
        }
    };



    const verticalBarRef = useRef(null); 

 
    const c1Ref = useRef(null);
    const c2Ref = useRef(null);
    const c3Ref = useRef(null);
    const path1Ref = useRef(null);
    const path2Ref = useRef(null);

    // Fonction pour obtenir la position centrale d'un élément
    function getPointPos(point) {
      const rect = point.getBoundingClientRect();
      return {
        left: rect.left + rect.width / 2 + window.scrollX,
        top: rect.top + rect.height / 2 + window.scrollY
      };
    }
  

    
    function createLine(source, target) {
      // Déterminez le milieu entre les points source et cible
      const midX = (source.left + target.left) / 2;
    
      // Calculez la hauteur à utiliser pour les points de contrôle pour créer un effet plus courbé
      const curveHeight = Math.abs(target.top - source.top);
    
      // Positionnez les points de contrôle pour qu'ils soient plus éloignés du chemin direct
      const cp1X = source.left;
      const cp1Y = source.top + curveHeight; // Point de contrôle en dessous de c1
    
      const cp2X = target.left;
      const cp2Y = target.top - curveHeight; // Point de contrôle au-dessus de c2
    
      // Utilisez maintenant la commande C pour la courbe cubique de Bézier dans l'attribut d
      // Cela créera une courbe qui part presque verticalement de c1 et arrive presque verticalement à c2
      return `M${source.left},${source.top} C${cp1X},${cp1Y} ${cp2X},${cp2Y} ${target.left},${target.top}`;
    }
    
    const [svgVisibility, setSvgVisibility] = useState('hidden');

    
    // Fonction pour mettre à jour les chemins SVG en fonction des positions actuelles des éléments
    function updateLines() {
      // Mettre à jour la ligne de c1 à c2
      if (c1Ref.current && c2Ref.current && path1Ref.current) {
        const c1Pos = getPointPos(c1Ref.current);
        const c2Pos = getPointPos(c2Ref.current);
        const c1Radius = c1Ref.current.offsetWidth / 2;
        const c2Radius = c2Ref.current.offsetWidth / 2;
    
        path1Ref.current.setAttribute('d', createLine(c1Pos, c2Pos, c1Radius, c2Radius));
      }
    
      // Ajouter une nouvelle ligne de c2 à c3
      if (c2Ref.current && c3Ref.current && path2Ref.current) {
        const c2Pos = getPointPos(c2Ref.current);
        const c3Pos = getPointPos(c3Ref.current);
        path2Ref.current.setAttribute('d', createLine(c2Pos, c3Pos));
      }
      setPathsRendered(true); 
      setSvgVisibility('visible');

    }
    
    
    // Pensez à appeler `updateLines` au bon moment, par exemple après un changement d'état ou dans le gestionnaire d'événements de clic du bouton "Parcours".
    
  
    // Gestionnaire de clic pour le bouton "Parcours"
    function handleParcoursClick() {
      // Ici, insérez toute logique supplémentaire nécessaire lors du clic
      // Par exemple, mise à jour de l'état ou navigation
  
      // Met à jour les lignes après un délai pour s'assurer que toutes les mises à jour de l'état ou du DOM sont terminées
      setTimeout(updateLines, 0);
    }
  

    const handleScroll = () => {
      const filterHPRect = filterHPRef.current.getBoundingClientRect();
      if (filterHPRect.top <= 0) {
        filterHPRef.current.classList.add('sticky-filter'); // Ajoutez une classe CSS pour appliquer le style sticky
      } else {
        filterHPRef.current.classList.remove('sticky-filter'); // Supprimez la classe CSS si l'élément n'est pas en haut de la fenêtre
      }
    };
    
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    


    const changeCategory = (category) => {
      setIsGradientFixed(true);
      setSelectedCategory(category);
      let ref;
      switch (category) {
        case 'compétences':
          ref = compétencesRef;
          break;
        case 'travaux':
          ref = travauxRef;
          break;
        case 'parcours':
          ref = parcoursRef;
          updateLines(); 
          break;
        default:
          ref = null;
      }
      if (ref && ref.current) {
        const buttonRect = ref.current.getBoundingClientRect();
        const filterHPRect = filterHPRef.current.getBoundingClientRect();
        const xPercent = ((buttonRect.left + buttonRect.width / 2 - filterHPRect.left) / filterHPRect.width) * 100;
        const yPercent = ((buttonRect.top + buttonRect.height / 2 - filterHPRect.top) / filterHPRect.height) * 100;
        setGradientPosition(`${xPercent}% ${yPercent}%`);
      }
      updateLines(); 
  
    };

    useEffect(() => {
      if (selectedCategory === 'parcours') {
        const animatePath = (pathRef, delay) => {
          if (pathRef && pathRef.current) {
            pathRef.current.style.animation = `fadeIn 5s forwards ${delay}s`;
          }
        };
    
        animatePath(path1Ref, 0);
        animatePath(path2Ref, 0); 
        updateLines();
      }
    }, [selectedCategory]);
    
    
    
    const [showPathAnimation, setShowPathAnimation] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowPathAnimation(true);
      }, 100); 
  
      return () => clearTimeout(timer);
    }, []);
  

    setTimeout(() => {
      setSvgVisibility('visible');
    }, 0);
    


    

    return ( 
    <> <div>
        <section
            className="de-3 section-padding"
            style={{
            textAlign: 'center'
        }}
            id="home">

            <div className="container herodivContainer">
            <div className="row justify-content-center align-items-center herodivRow">
                    <div className="col-md-6 herodivCol1  wow fadeInUp" data-wow-delay="0.1s">
                        <img src="../img/core-img/neuiHD.jpg" className='herodivImg1'/>
                    </div>
                    <div className="col-md-6 herodivCol2 wow fadeInUp" data-wow-delay="0.1ns">
                        <div className='cropIMG'><img src="../img/core-img/logoSBG.png" className='herodivImg2'/></div>
                        <h3 className="halenoir-h3 herodivTxt">{t('HPTC')}</h3>
                    </div>
                </div>
            </div>

        </section>

        <div className="clearfix"/>
        <div className='container'>

        <div>
            {/* Ici, vous pouvez avoir un bouton ou une logique pour sélectionner "Travaux" */}
            <TravauxGrid items={gridItems} openPdf={openPdf} />
          </div>

            <section
                className="darky how"
                style={{
                paddingTop: 0,
                paddingBottom: 50
            }}>
                <div className="container">
                    <div className="section-heading text-center">
                        <div
                            className="dream-dots justify-content-center wow fadeInUp"
                            data-wow-delay="0.2s">
                            <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider"/>
                        </div>
                        <h5
                            className="wow fadeInUp"
                            data-wow-delay="0.4s"
                            style={{
                            visibility: 'visible',
                            animationDelay: '0.4s',
                            animationName: 'fadeInUp'
                        }}>{t('HPsT')}</h5>





<section className="section-choix-categorie">
        {
          isScreenWidthBelow900 ? (
            // Afficher une version simplifiée pour les écrans de moins de 900px
            <div className='filterHPresp'>
            <button
              onClick={() => changeCategory('compétences')}
              className={selectedCategory === 'compétences' ? 'active' : ''}
            >
              Compétences
            </button>
            <button
              onClick={() => changeCategory('travaux')}
              className={selectedCategory === 'travaux' ? 'active' : ''}
            >
              Travaux
            </button>
            <button
              onClick={() => changeCategory('parcours')}
              className={selectedCategory === 'parcours' ? 'active' : ''}
            >
              Parcours
            </button>
          </div>
          ) : (
            <div>
            </div>
          )
        }
      </section>






                        <div className='sticky-filter '>
                        <div    className={`  filterHP wow fadeInUp ${isScreenWidthBelow900 ? 'mobile-filter' : ''} }`}
  data-wow-delay="0.1s"  ref={filterHPRef}  onMouseMove={handleMouseMove}
  style={{
    display: isScreenWidthBelow900 ? 'block' : 'flex',
    flexDirection: isScreenWidthBelow900 ? 'column' : 'row',
    justifyContent: 'center', // Centrer les boutons horizontalement s'ils sont en flex
    alignItems: 'center', // Centrer les boutons verticalement pour la disposition en colonne
    textAlign: 'center', // S'assurer que le texte des boutons est centré
  }}
>
  <button
    ref={compétencesRef}
    className={buttonClass('compétences')}
    onClick={() => changeCategory('compétences')}
  >
    Compétences
  </button>
  <button
    ref={travauxRef}
    className={buttonClass('travaux')}
    onClick={() => changeCategory('travaux')}
  >
    Travaux
  </button>
  <button
  ref={parcoursRef}
  className={buttonClass('parcours')}
  onClick={() => {
    changeCategory('parcours');
    handleParcoursClick();
    updateLines();
  }}
>
  Parcours
</button>

</div>
                        </div>

                    </div></div>

                    {selectedCategory === 'parcours' ? (
   <div className="container">
   <div className="row">
       
      
   </div>
  
        <div className="container " id="testParcours">
          
        <svg className='svgP' style={{ visibility: svgVisibility }}>
  <defs>
    <linearGradient id="gradientP1" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" style={{ stopColor: "#fb02ff", stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: "#b361fb", stopOpacity: 1 }} />
    </linearGradient>
    <linearGradient id="gradientP2" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" style={{ stopColor: "#b361fb", stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: "#7979f2", stopOpacity: 1 }} />
    </linearGradient>
    <linearGradient id="gradientP3" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" style={{ stopColor: "#7979f2", stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: "#6a81fb", stopOpacity: 1 }} />
    </linearGradient>
  </defs>
  
  <path
  ref={path1Ref}
  d="M10,10 L100,100"
  style={{
    stroke: "url('#gradientP1')",
    opacity: pathsRendered ? 1 : 0, // Seulement afficher quand pathsRendered est true
    animation: pathsRendered ? `fadeIn 2s forwards 1s` : 'none', // Appliquer l'animation seulement quand pathsRendered est true
  }}
/>

<path
  ref={path2Ref}
  d="M100,10 L10,100"
  style={{
    stroke: "url('#gradientP2')",
    opacity: pathsRendered ? 1 : 0, // Seulement afficher quand pathsRendered est true
    // Appliquez une animation similaire si nécessaire
  }}
/>

</svg>


  <div className="row align-items-center neuilP ">
    <div className="col-2 anchor-point">    
    <b className='c1 ' ref={c1Ref}>      <img src="img/icons/polytech.png" alt="Image 1" className="img-fluid wow fadeInUp" width={'150px'}/>
</b>
</div>
    <div className="col-2 d-flex justify-content-center align-items-center wow fadeInUp">
<h5>2022 - 2025</h5>    </div>
    <div className="col-8 child-margin wow fadeInUp">
      <h2>Polytech Sorbonne</h2>
      <h3>Diplôme d’état d’ingénieur</h3>
      <h4>MAIN : Mathématiques appliquées et Informatique</h4>
      <p>
      Une fois ma licence terminée, j’ai candidaté au cycle ingénieur MAIN de Polytech Sorbonne. Je savais en effet leurs cours de grande qualité, puisque dispensés par les enseignants et maîtres de conférence de Sorbonne-Université (anciennement Université Paris-VI : Pierre et Marie Curie). De plus, et contrairement aux masters universitaires classiques, la dualité de cette filière assure une grande polyvalence.    </p>
    </div>
  </div>


  <div className="row align-items-center neuilP wow fadeInUpt"  data-wow-delay="0.1s">
    <div className="col-8 text-righ wow fadeInUp" data-wow-delay="0.1s">
    <h2>Université de Paris-Cité</h2>
    <h3>Licence générale scientifique</h3>
      <h4>Informatique et mineures mathématiques </h4>
      
       <p>
       Mon intérêt pour les sciences formelles m’a poussé à intégrer le portail maths-info de l’Université de Paris-Cité (à l’époque appelée Université Paris-V : Descartes). J’y ai effectué une première année de double licence en mathématiques et en informatique. Je me suis ensuite spécialisé en informatique théorique et pratique (avec quelques mineures mathématiques néanmoins) pour mes deux dernières années au campus des Saints-Pères.
       </p>    </div>
    <div className="col-2  col d-flex align-items-center justify-content-center wow fadeInUp" data-wow-delay="0.1s">
<h5>2019 - 2022</h5>    </div>
    <div className="col-2 anchor-point">
    <b className='c2' ref={c2Ref}>  <img src="img/icons/parisC.png" alt="Image 1" className="img-fluid wow fadeInUp" data-wow-delay="0.1s" data-wow-delay="0.1s" width={'150px'}/></b>
         </div>
  </div>


  <div className="row align-items-center neuilP " >
  <div className="col-2 anchor-point " >  
  <b className='c3' ref={c3Ref}>  <img src="img/icons/jbs.png" alt="Image 1" className="img-fluid wow fadeInUp"  width={'150px'}/></b>
   </div>
    <div className="col-2 col d-flex align-items-center justify-content-center wow fadeInUp" >
    <h5>2016 - 2019</h5>     </div>
    <div className="col-8 wow fadeInUp" >
      <h2>Lycée Jean-Baptiste Say</h2>
      <h3>Diplôme d’état du baccalauréat</h3>
      <h4>Filière scientifique : Bac S spé physique</h4>
      <p>
      Suite à mon brevet mention très bien et à mes bonnes notes en classe de troisième au collège André Citroën, j’ai obtenu une place dans le lycée Jean-Baptiste Say. Après une seconde générale, j’y ai suivi une première puis une terminale S. J’ai ainsi obtenu mon baccalauréat scientifique spécialité physique en 2019 avec une mention Bien.
            </p>
    </div>
  </div>


  {/* <div className="row align-items-center  wow fadeInUp">
    <div className="col-8 text-right">
      <h2>Titre 4</h2>
      <p>Per a nullam condimentum bibendum nisl blandit a nisl cras a suspendisse venenatis ullamcorper parturient luctus placerat velit parturient. Libero a elit himenaeos vestibulum vestibulum a nostra in a enim vestibulum donec auctor feugiat at blandit a a nunc diam sagittis. A ullamcorper a a torquent aptent ac donec et ullamcorper a fringilla condimentum eu parturient condimentum adipiscing imperdiet est arcu hac consectetur. Fermentum mi condimentum tempor dui habitant at adipiscing velit rutrum felis parturient vel erat nulla scelerisque blandit enim metus inceptos a fames a. Sed vel vestibulum suspendisse varius magnis vel lobortis convallis a a parturient a facilisis natoque phasellus lobortis adipiscing dictum parturient.</p>
    </div>
    <div className="col-2 col d-flex align-items-center justify-content-center">
      <img src="img/icons/1.png" alt="Image 4" className="img-fluid" />
    </div>
    <div className="col-2 anchor-point ">
    <b className='c4' ref={c4Ref}>  <img src="img/icons/parisC.png" alt="Image 1" className="img-fluid" width={'150px'}/></b>
      </div>
  </div> */}
</div>



<div className="container" id="testParcours1">
  {/* Item 1 */}
  <div className="row align-items-center wow fadeInUp mb-5 service-single-content fadeParcResp">
    <div className="col-12 text-center mb-3">
      <img src="img/icons/polytech.png" alt="Polytech'Sorbonne" className="img-fluid" />
    </div>
    <div className="col-12 text-center">
      <h5>2022 - 2025</h5>
      <h2>Polytech Sorbonne</h2>
      <h3>Diplôme d’état d’ingénieur</h3>
      <h4>MAIN : Mathématiques appliquées et Informatique</h4>
    </div>
    <div className="col-12">
      <p>
      Une fois ma licence terminée, j’ai candidaté au cycle ingénieur MAIN de Polytech Sorbonne. Je savais en effet leurs cours de grande qualité, puisque dispensés par les enseignants et maîtres de conférence de Sorbonne-Université (anciennement Université Paris-VI : Pierre et Marie Curie). De plus, et contrairement aux masters universitaires classiques, la dualité de cette filière assure une grande polyvalence.    </p>
    </div>
  </div>

  {/* Item 2 */}
  <div className="row align-items-center wow fadeInUp mb-5 fadeParcResp">
    <div className="col-12 text-center mb-3">
      <img src="img/icons/parisC.png" alt="Université Paris Cité" className="img-fluid" />
    </div>
    <div className="col-12 text-center">
    <h5>2019 - 2022</h5>
    <h2>Université de Paris-Cité</h2>
    <h3>Licence générale scientifique</h3>
    <h4>Informatique et mineures mathématiques </h4>

    </div>
    <div className="col-12">
    <p>
    Mon intérêt pour les sciences formelles m’a poussé à intégrer le portail maths-info de l’Université de Paris-Cité (à l’époque appelée Université Paris-V : Descartes). J’y ai effectué une première année de double licence en mathématiques et en informatique. Je me suis ensuite spécialisé en informatique théorique et pratique (avec quelques mineures mathématiques néanmoins) pour mes deux dernières années au campus des Saints-Pères.
       </p>    </div>
  </div>

  {/* Item 3 */}
  <div className="row align-items-center wow fadeInUp mb-5 fadeParcResp">
    <div className="col-12 text-center mb-3">
      <img src="img/icons/jbs.png" alt="Stage" className="img-fluid" />
    </div>
    <div className="col-12 text-center">
    <h5>2016 - 2019</h5> 
    <h2>Lycée Jean-Baptiste Say</h2>
    <h3>Diplôme d’état du baccalauréat</h3>
    <h4>Filière scientifique : Bac S spé physique</h4>

    </div>
    <div className="col-12">
      <p>
      Suite à mon brevet mention très bien et à mes bonnes notes en classe de troisième au collège André Citroën, j’ai obtenu une place dans le lycée Jean-Baptiste Say. Après une seconde générale, j’y ai suivi une première puis une terminale S. J’ai ainsi obtenu mon baccalauréat scientifique spécialité physique en 2019 avec une mention Bien.
</p>    </div>
  </div>

  
</div>



<div className="col-lg-12 wow fadeInUp">
            <a href="cv.pdf" download className="custom-download-btn">
                <span className="custom-btn-content">
                    Télécharger mon CV au format PDF
                </span>
            </a>
        </div>
    </div>








) : (
    <div ref={masonryRef} key={selectedCategory || 'all'} className="row masonry-grid">
    {filteredGridItems.map((item, index) => (
      <GridItem 
        key={index} // Assurez-vous que la clé est unique pour chaque élément
        pdfPath={item.pdfPath}
        title={item.title}
        iconSrc={item.iconSrc}
        delay={`${0.05 * (index + 1)}s`}
        openPdf={openPdf}
        isTransparent={item.isTransparent}
        category={item.category}
        
      >
             <a href={item.href} className="service_single_content grediant-borders box-shadow d-flex">
                 <div className="service_icon">
                     <img draggable="false" src={item.iconSrc} alt="" />
                 </div>
                 <div className="service_content">
                     <h6>{item.title}</h6>
                 </div>
             </a>
         </GridItem>
        ))}
    </div>
)}

                    </section>
                </div>
            </div>
        </>
    );
};

export default HomePage;